
<div class="div">
  <app-navigation-menu [width]="innerWidth"></app-navigation-menu>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</div>

<!-- TODO: PROBLEM for SSR need to change menu to bootstrap -->
<!--<mat-drawer-container class="main-container" *ngIf="innerWidth < 1025 " style="height: 100%">
  <mat-drawer #drawer class="side-menu" [opened]="!isOpen" style="padding: 0" [mode]="'over'" cdk-scrollable="false">
    <mat-nav-list>
      <div class="listnav">
        <div class="start">
          Dom Menu
        </div>
        <div class="page" *ngFor="let m of menu"
             routerLink="{{m.router}}"
             [routerLinkActive]="['active']"
             [routerLinkActiveOptions]="{exact:true}"
             (click)="drawer.toggle()"
             style="cursor: pointer"
        >
          <span><i class="{{m.icon}}"></i> {{m.name|translate}}</span>
        </div>
      </div>
    </mat-nav-list>

    <div class="copyright">
      <div class="language-mobile">
        &lt;!&ndash;TODO: Need to fix language dropdown for mobile&ndash;&gt;
        &lt;!&ndash;<div class="language-selector"
             ngbDropdown
             #myDrop="ngbDropdown"
             aria-expanded=false
             [routerLinkActive]="['active']">

          <a class="nav-link"
             role="button"
             ngbDropdownToggle
             style="cursor: pointer;user-select: none;display: flex">

            <img class="flag" src="{{currentLanguage.flag}}">
            <div class="label">{{currentLanguage.label | translate}}</div>
          </a>

          <div ngbDropdownMenu
               class="dropdown-menu show"
               aria-labelledby="dropdownManual"
               (click)="navbarCollapsed = false">

            <button class="dropdown-item"
                    *ngFor="let language of availableLanguages"
                    (click)="setLanguage(language)">

              <img class="flag" [src]="language.flag">
              {{language.label | translate}}
            </button>
          </div>
        </div>&ndash;&gt;
      </div>
      <div class="social">
        <div class="circle f">
          <i class="fab fa-facebook-f"></i>
        </div>

        <div class="circle t">
          <i class="fab fa-twitter"></i>
        </div>

        <div class="circle g">
          <i class="fab fa-google-plus-g"></i>
        </div>
      </div>
      <div class="copy">Copyright by Dom D.O.O 2023</div>
    </div>
  </mat-drawer>

  <mat-toolbar id="brand">
    <a class="navbar-brand"
       [routerLink]="['/']"
       (click)="navbarCollapsed = false"
    >
      <div class="logo" style="justify-content: center;height: 100%">
        <img src="../assets/images/logo/pavas-logo-white.png" style="height: 100%;max-height: 40px">
        <img src="../assets/images/logo/pavas-logo-text-white.png" style="height: 100%;max-height: 40px">
      </div>
    </a>
    <button mat-icon-button (click)="drawer.toggle()" style="display: flex;outline: none;align-items: center">
      <mat-icon>reorder</mat-icon>
      <p>{{name|translate}}</p>
    </button>
  </mat-toolbar>

  <div class="content" style="padding-top: 100px">
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
</mat-drawer-container>-->



